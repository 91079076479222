import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

// preload views
//import Home from '../views/Home.vue';
import SignIn from '../views/SignIn.vue';
import Account from '../views/Account.vue';
import Error404 from '../views/Error404.vue';

// load view when route visited (lazy load)
const Shop = () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
const Fund = () => import(/* webpackChunkName: "fund" */ '../views/Fund.vue')
const Press = () => import(/* webpackChunkName: "press" */ '../views/Press.vue')
const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const Checkout = () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
const Album = () => import(/* webpackChunkName: "album" */ '../views/Album.vue')
const AccountDev = () => import(/* webpackChunkName: "accountDev" */ '../views/AccountDev.vue')
const AccountProfile = () => import(/* webpackChunkName: "accountProfile" */ '../views/AccountProfile.vue')
const AccountOrders = () => import(/* webpackChunkName: "accountOrders" */ '../views/AccountOrders.vue')
const AccountProjects = () => import(/* webpackChunkName: "accountProjects" */ '../views/AccountProjects.vue')
const AccountProject = () => import(/* webpackChunkName: "accountProject" */ '../views/AccountProject.vue')
const AccountUsers = () => import(/* webpackChunkName: "accountUsers" */ '../views/AccountUsers.vue')

// album service needed for valid slug check
import AlbumService from '../services/AlbumService.js';

const routes = [
/*	{
		path: '/',
		name: 'Home',
		meta: {
			requireLogin: false,
			title: 'Home | The Record Republic'
		},
		component: Home
	},*/
	{
		path: '/signin',
		name: 'SignIn',
		meta: {
			requireLogin: false,
			title: 'Sign in | The Record Republic'
		},
		component: SignIn	
	},
	{
//		path: '/shop-vinyl',
		path: '/',
		name: 'Shop',
		meta: {
			requireLogin: false,
			title: 'Shop Vinyl | The Record Republic'
		},
		component: Shop
	},
	{
		path: '/fund-vinyl',
		name: 'Fund',
		meta: {
			requireLogin: false,
			title: 'Fund Vinyl | The Record Republic'
		},
		component: Fund
	},
	{
		path: '/press-vinyl',
		name: 'Press',
		meta: {
			requireLogin: false,
			title: 'Press Vinyl | The Record Republic'
		},
		component: Press
	},
	{
		path: '/about',
		name: 'About',
		meta: {
			requireLogin: false,
			title: 'Our Story | The Record Republic'
		},
		component: About
	},
	{
		path: '/account',
		name: 'Account',
		meta: {
			requireLogin: true, // inherited by child routes
			title: 'Account | The Record Republic'
		},
		children: [
			{
				path: 'development', // e.g. /accout/dev
				name: 'Development',
				meta: {
					requireLogin: true,
					title: 'Development | The Record Republic'
				},
				component: AccountDev
			},
			{
				path: 'profile', // e.g. /accout/profile
				name: 'Profile',
				meta: {
					requireLogin: true,
					title: 'Profile | The Record Republic'
				},
				component: AccountProfile
			},
			{
				path: 'orders',
				name: 'Orders',
				meta: {
					requireLogin: true,
					title: 'Orders | The Record Republic'
				},
				component: AccountOrders
			},
			{
				path: 'projects',
				name: 'Projects',
				meta: {
					requireLogin: true,
					title: 'Projects | The Record Republic'
				},
				component: AccountProjects
			},
			{
				path: 'projects/:id',
				name: 'Project',
				meta: {
					requireLogin: true,
					title: 'Project | The Record Republic'
				},
				component: AccountProject
			},
			{
				path: 'users',
				name: 'Users',
				meta: {
					requireLogin: true,
					title: 'Users | The Record Republic'
				},
				component: AccountUsers
			},
		],
		component: Account
	},
	{
		path: '/checkout',
		name: 'Checkout',
		meta: {
			requireLogin: false,
			title: 'Checkout | The Record Republic'
		},
		component: Checkout
	},
	{
		path: '/albums/:slug',
		name: 'Album',
		meta: {
			requireLogin: false,
			title: 'Album | The Record Republic'
		},
		component: Album,
		beforeEnter: async (to, from, next) => {
			// check album exists
			const {data} = await AlbumService.getBySlug(to.params.slug);
			
			if (!data.album) {
				next({
					name: 'Error404',
					// mirror requested url
					params: { pathMatch: to.path.split('/').slice(1) },
					query: to.query,
					hash: to.hash
				});
			}
			
			next();
		}
	},	
	{ // page not found
		path: '/:pathMatch(.*)*',
		name: 'Error404',
		component: Error404
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
});

// navigation guards
router.beforeEach( async (to, from, next) => {
	// update title tag
	document.title = to.meta.title || 'The Record Republic'; // default
	
	// auth check
	const requireLogin = to.matched.some(record => record.meta.requireLogin);
	
	if (!store.state.auth.checked) {
		// set auth status
		await store.dispatch('auth/status');
	}
	
	if (to.name !== 'SignIn' && requireLogin && !store.state.auth.loggedIn) {
		next({name:'SignIn'});
		
	} else if (to.name === 'SignIn' && store.state.auth.loggedIn) {
		next({name:'Profile'});
		
	} else {
		next();
	}
});

export default router;