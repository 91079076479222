<template lang="pug">
span.price
	small(v-if="label") {{label}}
	small &pound;
	em {{pricePound}}
	small .{{pricePence}}
	strong(v-if="vat==='inc'") Inc. VAT
	strong(v-if="vat==='exc'") Exc. VAT
</template>

<script>
export default {
	name: 'Price',
	props: [
		'price',
		'label',
		'vat',
	],
	computed: {
		priceFormatted() {
			return this.currencyFormat(this.price);
		},
		pricePound() {
			let arr = this.priceFormatted.split('.');
			return arr[0].replace(/\D/g,''); // strip £ and thousand seperator
		},
		pricePence() {
			let arr = this.priceFormatted.split('.');
			return arr[1]; // pence
		},
	},
	methods: {
		currencyFormat(amount) {
			const formatter = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
			});
			
			return formatter.format(amount);
		},
	},
};
</script>

<style lang="scss">
.price {
	position: relative;
	small {
		font-size: 1.4rem;
		&:nth-last-of-type(3) { // label (e.g. total:)
			margin-right: 10px;
		}
	}
	em {
		font-size: 2.2rem;
		font-style: normal;
	}
	strong {
		display: block;
		margin: -6px 0 2px 0;
		font-size: .9rem;
		font-weight: 600;
		text-align: right;		
		text-transform: uppercase;
	}
}
</style>