import httpApi from '../httpApi';

class AlbumService {
	// api calls
	getAll() {
		return httpApi.get('/albums');
	}
	getPublished() {
		return httpApi.get('/albums/published');
	}
	getById(id) {
		return httpApi.get(`/albums/id/${id}`);
	}
	getBySlug(slug) {
		return httpApi.get(`/albums/slug/${slug}`);
	}
//	getFunded() {
//		return httpApi.get('/albums?state=funding');
//	}
//	getByState(state) {
//		return httpApi.get(`/tutorials?state=${state}`);
//	}	
	findByTitle(title) {
		return httpApi.get(`/albums?title=${title}`);
	}
}

export default new AlbumService();