import httpApi from '../httpApi';
//import authHeader from './authHeader';

class UserService {
	getUserById(id) {
		return httpApi.get(`/user/${id}`);
	}
	
	updateCustomerId(data) {
		// user id pulled from backend session
		return httpApi.put('/user/customerId', {
			id: data.id
		});
	}
/*		
	getUsers() {
		return httpApi.get('/user/all');
	}
	
	
	getUserBoard() {
		return httpApi.get('/test/user', { headers: authHeader() });
	}
	
	getModeratorBoard() {
		return httpApi.get('/test/mod', { headers: authHeader() });
	}
	
	getAdminBoard() {
		return httpApi.get('/test/admin', { headers: authHeader() });
	}*/
}

export default new UserService();