import axios from 'axios';

const apiBaseUrl = (process.env.VUE_APP_VUE_ENV === 'production') ? window.location.origin + '/api' : 'http://localhost:8080/api';

axios.defaults.withCredentials = true; // requests should be made using cookie credentials

export default axios.create({
	baseURL: apiBaseUrl,
	headers: {
		'Content-type': 'application/json'
	},
});