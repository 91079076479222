<template lang="pug">
include ../pug/svg
transition(name="fade")
	div.cookie(v-if="!accepted" role="alert")
		button.icon.pulse.close(type="button" @click="acceptAndClose")
			+svg(svg-filename="iconClose")
			span Close cookie notice
		p To provide the best possible experience, our site uses cookies. By continuing to browse you are accepting our <a href="#">Cookie Policy</a>.
		button.text(@click="acceptAndClose") Accept &amp; Close
</template>

<script>
export default {
	name: 'CookieNotice',
	data() {
		return {
			accepted: false,
		};
	},
	computed: {
	},
	methods: {
		acceptAndClose() {
			this.accepted = true;
			localStorage.setItem('cookieNotice', true);
		},
	},
	created: function() {
		this.accepted = JSON.parse(localStorage.getItem('cookieNotice'));
	},
};
</script>

<style lang="scss">
.cookie {
	z-index: 140;
	position: fixed;
	bottom: 30px; // clear copyright
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 40px);
	max-width: 380px;
	padding: 20px 20px 10px 20px;
	border-radius: 4px;
	text-align: center;
	font-size: 1.2rem;
	background: #FFF;
	box-shadow: 0 2px 10px rgba(#000, .2);
	p {
		margin-bottom: 10px;
	}
	a {
		color: $grey;
		&:hover {
			color: $green;
		}
	}
	.text {
		padding: 0;
		transition: .3s ease-in-out;
		&:hover {
			color: $grey;
		}
	}
	.close {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%) scale(.6); // 24px
	}
}
</style>