import AlbumService from '../services/AlbumService';

export const albums = {
	namespaced: true,
	state: {
		albums: [],
		vinylColour: [
			// standard colours
			{option: 'Black', value: 'black', type: 'standard', style: 'background-color', colour: '#323232', blend: 'plus-lighter'},
			{option: 'Red Solid', value: 'No9STDTM', type: 'standard', style: 'background-color', colour: '#C71415', blend: 'plus-lighter' },
			{option: 'Orange Transparent', value: 'No3STDTM', type: 'standard', style: 'background', colour: '#F05E0A', blend: 'plus-lighter'},
			{option: 'Blue Solid', value: 'No12STDTM', type: 'standard', style: 'background-color', colour: '#023DB7', blend: 'plus-lighter'},
			{option: 'Yellow Solid', value: 'No10STDTM', type: 'standard', style: 'background', colour: '#EACB16', blend: 'plus-lighter'},
			{option: 'Green Solid', value: 'No11STDTM', type: 'standard', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
			// limited edition colours
//			{option: 'Pink Opaque', value: 'No17SPCTM', type: 'limited', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
			// splatter colours
//			{option: 'No7', value: 'No7SPEECLAT', type: 'splatter', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
			// marble colours
//			{option: 'No1', value: 'No1STDMARBRE', type: 'marble', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
		],
	},
	mutations: {
		setAlbums(state, albums) {
			state.albums = albums;
		},
		incrementStock(state, { album, increment }) {
			const index = state.albums.findIndex(a => a.id === album.id);			
			state.albums[index].stock += increment;
		},
		decrementStock(state, { album, decrement }) {
			const index = state.albums.findIndex(a => a.id === album.id);			
			state.albums[index].stock -= decrement;
		},
	},
	actions: {
		async dbAllAlbums({ commit }) {
			try {
				const {data} = await AlbumService.getPublished();
				commit('setAlbums', data.albums);
				
			} catch (error) {
				console.log(error);
			}			
		},
	},
	getters: {
		allAlbums: (state) => {
			return state.albums;
		},
		albumBySlug: (state) => (slug) => {
			const index = state.albums.findIndex(a => a.slug === slug);
			return (index >= 0) ? state.albums[index] : null;
		},
		albumStock: (state) => (id) => {
			// returns stock qty for album matching id
			const index = state.albums.findIndex(a => a.id === id);
			
			return (index >= 0) ? state.albums[index].stock : 0;
		},
	}
};
