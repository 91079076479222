import AuthService from '../services/AuthService';
import PaymentService from '../services/PaymentService';
import UserService from '../services/UserService';
import AccountService from '../services/AccountService';

const initialState = JSON.parse(localStorage.getItem('recoverAccount')) ? true : false;

export const auth = {
	namespaced: true,
	state: {
		checked: false,
		loggedIn: false,
		user: null,
		recoverAccount: initialState,
	},
	mutations: {
		signupSuccess(state, user) {
			state.checked = false;
			state.loggedIn = false;
			state.user = user;
		},
		signupFailure(state) {
			state.checked = false;
			state.loggedIn = false;
			state.user = null;
		},
		signinSuccess(state, user) {
			state.checked = true;
			state.loggedIn = true;
			state.user = user;
		},
		signinFailure(state) {
			state.checked = true;
			state.loggedIn = false;
			state.user = null;
		},
		signoutSuccess(state) {
			state.loggedIn = false;
			state.user = null;
		},
		updateCustomerSuccess(state, customer) {
			state.user.customer = customer;
		},
		updateUserSuccess(state, user) {
			state.user = user;
		},
		INITIATE_RECOVERY(state) {
			state.recoverAccount = true;
		},
		CANCEL_RECOVERY(state) {
			state.recoverAccount = false;
		},
	},
	actions: {
		async initiateRecovery({ commit }, formData) {
			// NOTE: not in try/catch as we want error returned to view
			await AuthService.recover(formData.email);
			commit('INITIATE_RECOVERY');
			localStorage.setItem('recoverAccount', true);				
		},
		cancelRecovery({ commit }) {
			localStorage.removeItem('recoverAccount');
			commit('CANCEL_RECOVERY');
		},		
		async verifyRecovery({ commit, dispatch }, formData) {
			try {
				const response = await AuthService.reset(formData);
				
				dispatch('cancelRecovery');
				commit('signinSuccess', response.data.user);
				
			} catch (error) {
				console.log(error.response.data);
			}
		},
		async status({ commit }) {
			try {
				const response = await AuthService.status();
				
				if (response.data.loggedIn) {
					commit('signinSuccess', response.data.user);
				} else {
					commit('signinFailure');
				}
				
			} catch (error) {
				console.log(error.response.data);
				commit('signinFailure');
			}
		},
		async signup({ commit }, formData) {
			try {
				const {data} = await AuthService.signup(formData);
				commit('signupSuccess', data.user);
				
			} catch (error) {
				console.log('THERE:',error.response.data);
				commit('signupFailure');
			}				
		},
		async signin({ commit }, formData) {
			try {
				const response = await AuthService.signin(formData);
				commit('signinSuccess', response.data.user);
				
			} catch (error) {
				console.log(error.response.data);
				commit('signinFailure');
			}
		},
		async signout({ commit }) {
			try {
				await AuthService.signout();
				commit('signoutSuccess');
			} catch (error) {
				console.log(error.response.data);
			}
		},	
		async updateProfile({ commit }, formData) {
			try {
				const {data} = await AccountService.updateProfile(formData);
				
				commit('updateUserSuccess', data.user);
				commit('updateCustomerSuccess', data.customer);
				
			} catch (error) {
				console.log('updateProfile:error:', error);
			}
		},
		async updateCustomer({ commit, state}, formData) {
			try {
				if (state.user) {
					const newCustomer = (state.user.customer) ? false : true;
					
					const {data} = await PaymentService.upsertCustomer({
						email: state.user.email,
						firstName: formData.firstName,
						lastName: formData.lastName,
						businessName: formData.businessName||null,
						shipPhone: formData.shipPhone,
						shipName: formData.firstName + ' ' + formData.lastName,
						shipAddress1: formData.shipAddress1,
						shipAddress2: formData.shipAddress2||null,
						shipTown: formData.shipTown,
						shipCounty: formData.shipCounty||null,
						shipPostcode: formData.shipPostcode,
						shipCountry: formData.shipCountry,
					});
					
					if (newCustomer) {
						// update user db record
						await UserService.updateCustomerId({
							id: data.id
						});
					}
					
					const customer = {
						id: data.id,
						businessName: data.name,
						shipping: data.shipping,
						firstName: data.metadata.first_name,					
						lastName: data.metadata.last_name,					
					}
					
					commit('updateCustomerSuccess', customer);
				}
				
			} catch (error) {
				console.log('updateCustomer:error:', error);
			}
		},
	},
};
