<template lang="pug">
include ../pug/svg
div#basket
	button.icon.pulse.close(type="button" @click="hideBasket")
		+svg(svg-filename="iconClose")
		span Hide Basket
	ol.steps
		li(:class="{done:step>=1,active:step==1}") Items
		li(:class="{done:step>=2,active:step==2}") Details
		li(:class="{done:step>=3,active:step==3}") Delivery
		li(:class="{done:step>=4,active:step==4}") Payment
	div
		transition(name="fadestep")
			div.items(v-if="step==1")
				p.empty(v-if="!basketItems.length") Your basket is currently empty
				ul
					li(v-for="(item, index) in basketItems" :key="index") 
						hgroup
							h5 {{item.item.title}}
							h6 {{item.item.artist}}
						button.bin(type="button" aria-label="Remove item from basket" @click="removeFromBasket(item)")
							+svg(svg-filename="iconBin")
							span Remove
						div.qty
							span(aria-hidden="true") Qty.
							button.sub(type="button" aria-label="Remove 1 from item quantity" @click="removeOneFromBasket(item)")
								+svg(svg-filename="iconMinus")
								span Remove One
							button.add(type="button" aria-label="Add 1 to item quantity" @click="addToBasket(item)" :disabled="!itemStock(item)")
								+svg(svg-filename="iconPlus")
								span Add One
							em(aria-label="Quantity") {{item.qty}}
							label(:for="'item'+index" aria-label="Donate to the artist") Donation
								input(:id="'item'+index" type="number" min="0" max="9999" v-model.number="item.donation" @change="updateDonation(item)")
							Price(:price="(item.qty * item.item.price) + item.donation" vat="exc")
						span.sku {{item.item.trrSku}} / {{item.item.catNum}}
				ul.ctrl
					li.empty
						button.text.outline(type="button" @click="emptyBasket" :disabled="!basketTot") Empty			
					li.but-price 
						Price(:price="basketTot" label="Total" vat="exc")
						button.text(type="button" @click="doCheckout" :disabled="!basketTot") Checkout
		transition(name="fadestep")
			Form.details(ref="step2" v-if="step==2" :validation-schema="detailsSchema" v-slot="{ handleReset, errors, isSubmitting, values, meta }" @submit="doDetails")
				fieldset
					legend Contact Details
					label
						Field(name="email" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.email:''" :class="{err:errors.email}" :disabled="curUser" :validateOnInput="false")
						span Email
						em.signout(v-if="curUser") Wrong email?&nbsp;
							a(@click="doSignout") Change
						transition(name="fade")
							ErrorMessage(name="email" as="em")
					div(v-if="!curUser")
						transition(name="fade")
							label
								Field(name="hasAccount" type="checkbox" :value="true")
								span I have an account with The Record Republic
								
						transition(name="fadedelay")
							//-div(v-show="values.hasAccount&&!values.forgotPassword&&!recoverAccount")
							div(v-show="values.hasAccount&&!recoverAccount")
								label
									Field(name="password" type="password" placeholder="Required" autocomplete="off" :class="{err:errors.password}")
									span Password
									transition(name="fade")
										ErrorMessage(name="password" as="em")
								label.below()
									Field.a(name="forgotPassword" type="checkbox" :value="true" @click="initiateRecovery(values.email)" :disabled="!values.email||errors.email")
									span Forgotten your password?
						transition(name="fadedelay")
							//-div(v-if="values.hasAccount&&!errors.email&&(values.forgotPassword||recoverAccount)")
							div(v-if="values.hasAccount&&!errors.email&&recoverAccount")
								p.small.green We've emailed your password reset code&hellip;
								label
									Field(name="otpPassword" type="password" placeholder="Required" autocomplete="off" :class="{err:errors.otpPassword}")
									span Password Reset Code
									transition(name="fade")
										ErrorMessage(name="otpPassword" as="em")
								label
									Field(name="newPassword" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.newPassword}")
									span New Password
									transition(name="fade")
										ErrorMessage(name="newPassword" as="em")
								label.below
									//-button.a(name="forgotCancel" type="button" @click="handleReset") Cancel reset
									button.a(name="forgotCancel" type="button" @click="cancelRecovery") Cancel reset
						transition(name="fadedelay")
							div(v-if="!values.hasAccount")
								label
									Field(name="subscribe" type="checkbox" :value="true")
									span Keep me up to date on new releases and offers
								label
									Field(name="saveDetails" type="checkbox" :value="true")
									span Store my details for future orders
								transition(name="fadedelay")
									label(v-if="values.saveDetails")
										Field(name="createPassword" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.createPassword}")
										span Create Password
										transition(name="fade")
											ErrorMessage(name="createPassword" as="em")
				ul.ctrl
					li.empty
						button.text.outline(type="button" @click="prevStep()") Back			
					li.but-price 
						Price(:price="basketTot" label="Total" vat="exc")
						button.text(type="submit" :disabled="!meta.valid||isSubmitting") Next
		transition(name="fadestep")
			Form.delivery(ref="step3" v-if="step==3" :validation-schema="deliverySchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="doDelivery")
				fieldset
					legend Delivery Details
					label
						Field(name="business" type="checkbox" class="aorb" :value="true" v-model="business")
						span(:class="{unchecked:values.business}") Individual
						span(:class="{unchecked:!values.business}") Business
					label.hlf
						Field(name="firstName" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.firstName:''" :class="{err:errors.firstName}")
						span First name
					label.hlf
						Field(name="lastName" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.lastName:''" :class="{err:errors.lastName}")
						span Last name
					label
						Field(name="shipPhone" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.shipping?.phone:''" :class="{err:errors.shipPhone}")
						span Phone
						transition(name="fade")
							ErrorMessage(name="shipPhone" as="em")
					transition(name="fade")
						label(v-if="values.business")
							Field(name="businessName" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.businessName:''" :class="{err:errors.businessName}")
							span Business name
					label
						Field(name="shipAddress1" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.shipping?.address?.line1:''" :class="{err:errors.shipAddress1}")
						span Address
					label
						Field(name="shipAddress2" type="text" placeholder="Optional" autocomplete="off" :value="curUser?curUser.customer?.shipping?.address?.line2:''" :class="{err:errors.shipAddress2}")
						span Address
					label
						Field(name="shipTown" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.shipping?.address?.city:''" :class="{err:errors.shipTown}")
						span Town
					label.hlf
						Field(name="shipCounty" type="text" placeholder="Optional" autocomplete="off" :value="curUser?curUser.customer?.shipping?.address?.state:''" :class="{err:errors.shipCounty}")
						span County
					label.hlf
						Field(name="shipPostcode" type="text" placeholder="Required" autocomplete="off" :value="curUser?curUser.customer?.shipping?.address?.postal_code:''" :class="{err:errors.shipPostalcode}")
						span Postcode
					label
						Field(v-slot="{value}" name="shipCountry" as="select" placeholder="Required" autocomplete="off" :class="{err:errors.shipCountry,empty:!values.shipCountry}" v-model="shipCountry")
							option(v-for="(country, index) in pandp.countries" :value="country.code" :selected="value&&value===country.code") {{country.country}}	
						span Country
						+svg(svg-filename="iconArrow")
						transition(name="fade")
							ErrorMessage(name="shipCountry" as="em")
					Field(name="dirty" type="hidden" v-model="meta.dirty")
				h6 Delivery Charge
				p.small
					Price(:price="deliveryCharge")
					span &nbsp;({{courierService}})
				p.legal(v-if="hasNonStockItem") Your order contains non-stock items. All order items will be shipped in a single delivery once everything is available for dispatch.
				transition(name="fade")
					p.legal(v-if="shipZone !== 'uk'") You may be required to pay customs or VAT charges and a handling fee before claiming your delivery. Any such charges will depend on the country of delivery, the order value and whether it is a gift or commercial goods.
				div.busy(v-show="isSubmitting") 
					span Please wait				
					+svg(svg-filename="iconBusy")
				ul.ctrl
					li.empty
						button.text.outline(type="button" @click="prevStep()") Back			
					li.but-price 
						Price(:price="paymentTot" label="Total" vat="exc")
						button.text(type="submit" :disabled="!meta.valid||isSubmitting") Next
		transition(name="fadestep")
			Form.payment(v-if="step==4" v-slot="{ errors, isSubmitting, values, meta }" @submit="doPayment")
				h6 Payment Summary
				table
					tr
						th Items Total
						td
							Price(:price="itemsTot")
					tr
						th Delivery ({{courierService}})
						td
							Price(:price="deliveryCharge")
					tr
						th VAT
						td
							Price(:price="vatCharge")
					tr
						th Donations Total (VAT exempt)
						td
							Price(:price="donationTot")
				h6 Payment Details
				div#card-element
				p#card-errors(role="alert")
				div.busy(v-show="isSubmitting") 
					span Please wait				
					+svg(svg-filename="iconBusy")
				ul.ctrl
					li.empty
						button.text.outline(type="button" @click="prevStep()") Back			
					li.but-price 
						Price(:price="vatTot" label="Total" vat="inc" as="span")
						button.text(type="submit" :disabled="!meta.valid||isSubmitting") Pay Now
		transition(name="fadestep")
			div.complete(v-if="step==5")
				h6 Thank you for your purchase!
				p You will receive a confirmation email with details of your order.
				p Your order reference is 
					strong {{orderRef}}
				button.text.outline(type="button" @click="hideBasket()") Continue Shopping			
</template>

<script>
import Price from './Price';
import PaymentService from '../services/PaymentService';
import AuthService from '../services/AuthService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup); // extend yup

const stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY; // NOTE: will return live key if running in build version even if nodejs is running dev
const stripe = window.Stripe(stripeKey);
const elements = stripe.elements();
let card = null;
	
export default {
	name: 'Basket',
//	emits: ['addedToBasket'],
	components: {
		Form,
		Field,
		ErrorMessage,
		Price,
	},
	data() {
		const detailsSchema = Yup.object().shape({
			email: Yup.string().label('Email').email().required(),
			password: Yup.string().label('Password').when(['hasAccount','forgotPassword'], {
				is: (hasAccount, forgotPassword) => hasAccount && !forgotPassword,
				then: Yup.string().required()
			}),
			createPassword: Yup.string().label('Password').when('saveDetails', {
				is: true,
				then: Yup.string().password().required()
			}),
			otpPassword: Yup.string().when('forgotPassword', {
				is: true,
//				then: Yup.number().typeError('Reset Code requires 8 digits').test('len','Reset Code requires 8 digits', val => val && val.toString().length === 8)
				then: Yup.string().required()
			}),
			newPassword: Yup.string().label('Password').when('forgotPassword', {
				is: true,
				then: Yup.string().password().required()
			}),
		});
		
		const deliverySchema = Yup.object().shape({
			firstName: Yup.string().label('First Name').required(),
			lastName: Yup.string().label('Last Name').required(),
			shipPhone: Yup.string().label('Phone').required(),
			businessName: Yup.string().label('Business Name').when('business', {
				is: true,
				then: Yup.string().required()
			}),
			shipAddress1: Yup.string().label('Address').required(),
			shipAddress2: Yup.string(),
			shipTown: Yup.string().label('Town').required(),
			shipCounty: Yup.string().label('County'),
			shipPostcode: Yup.string().label('Postcode').required(),
			shipCountry: Yup.string().label('Country').required(),
		});
		
		return {
			detailsSchema,
			deliverySchema,
			pandp: [],
			step: 1,
//			email: null,
			shipZone: 'uk',
			paymentSecret: null,
			orderRef: null,
		};
	},
	async created() {
		const {data} = await PaymentService.getDelivery();
		this.pandp = data;
	},	
	mounted() {
//		card = elements.create('card', style);
		card = elements.create('card');
	},
	watch: {
		step(val) {	
			if (val === 4) {
				// wait for v-if transition (.6s)
				setTimeout(function(){
					// initiate elements card
					card.mount('#card-element');
					// manage errors
					card.on('change', ({error}) => {
						let displayError = document.getElementById('card-errors');
						if (error) {
							displayError.textContent = error.message;
						} else {
							displayError.textContent = '';
						}
					});
					
				}, 600);
			}
		}
	},	
	methods: {
		validateEmail(email) {
			this.$validator.validate('email', email);
		},
		async initiateRecovery(email) {
//			await this.$store.dispatch('auth/initiateRecovery', {
//				email: email,
//			});			
			try {
				await this.$store.dispatch('auth/initiateRecovery', {
					email: email,
				});
				
			} catch (error) {
				// supplied email failed recovery
				this.$refs.step2.setFieldError('email', 'Unknown email address');
			}
		},
		cancelRecovery() {
			this.$store.dispatch('auth/cancelRecovery');			
		},
		hideBasket() {
			this.step = 1;
			this.$store.dispatch('basket/hideBasket');
		},
		updateDonation(item) {
			this.$store.dispatch('basket/updateDonation', {
				item: item.item,
				type: item.type,
				donation: item.donation
			});
		},
		addToBasket(item) {
			this.$store.dispatch('basket/addToBasket', {
				item: item.item,
				type: item.type
			});
		},
		removeOneFromBasket(item) {
			this.$store.dispatch('basket/removeOneFromBasket', {
				item: item.item,
				type: item.type
			});		
		},
		removeFromBasket(item) {
			this.$store.dispatch('basket/removeFromBasket', {
				item: item.item,
				type: item.type
			});
		},
		emptyBasket() {
			this.$store.dispatch('basket/emptyBasket');
		},
		itemStock(item) {
			if (item.type === 'album') {
				return this.$store.getters['albums/albumStock'](item.item.id);
			} else {
				// TODO: merchandise (phase 2)
			}
		},
		prevStep() {
			this.step --;			
		},
		async doSignout() {
			try {
				await this.$store.dispatch('auth/signout');
				this.$refs.step2.setValues({email: ''});
				this.shipZone = this.pandp.countries.find(c => c.code === this.shipCountry).zone;
				
			} catch (error) {
				console.log(error.response.data.errors);
			}
		},
		async reloadAlbums() {
			// reload albums from database
			await this.$store.dispatch('albums/dbAllAlbums');
			// check stock quantities against basket items
			await this.$store.dispatch('basket/initiateStock');
		},
		async doCheckout() {
			// reload albums from database
			await this.reloadAlbums();
			// checkout
			this.step ++;
		},
		async doDetails(formData, actions) {
			try {
//				this.email = formData.email;
				
				if (this.curUser) {
					this.shipZone = this.pandp.countries.find(c => c.code === this.shipCountry).zone;
					this.step ++;
					
				} else if (formData.hasAccount === true) {
					if (formData.forgotPassword === true) {
						// account recovery
						await this.$store.dispatch('auth/verifyRecovery', {
							email: formData.email,
							otpPassword: formData.otpPassword,
							newPassword: formData.newPassword
						});
						
						if (!this.$store.state.auth.loggedIn) {
							// supplied credentials failed auth
							actions.setFieldError('otpPassword', 'Invalid password reset code, please try again');
						}
						
					} else {
						// account signin
						await this.$store.dispatch('auth/signin', {
							email: formData.email,
							password: formData.password
						});
						
						if (!this.$store.state.auth.loggedIn) {
							// supplied credentials failed auth
							actions.setFieldError('password', 'Invalid password, please try again');
							
						} else {
							this.shipZone = this.pandp.countries.find(c => c.code === this.shipCountry).zone;
							this.step ++;
						}
					}
					
				} else if (formData.saveDetails === true) {					
					// check for existing non-guest account
					const {data} = await AuthService.identify(formData);
					
					if (data.exists) {
						actions.setFieldError('email', 'This email is already taken');
						
					} else {
						// create account
						const subscribe = (formData.subscribe === true) ? 1 : 0;
						
						await this.$store.dispatch('auth/signup', {
							email: formData.email,
							password: formData.createPassword,
							subscribe: subscribe,
							silent: false
						});
						
						this.step ++;
					}
					
				} else { // guest checkout
					// check for existing non-guest account
					const {data} = await AuthService.identify(formData);
					
					if (data.exists) {
						actions.setFieldValue('hasAccount', true);
						actions.setFieldError('email', 'This email is already in use');
						
					} else {
						const subscribe = (formData.subscribe === true) ? 1 : 0;
						
						await this.$store.dispatch('auth/signup', {
							email: formData.email,
							password: null,
							subscribe: subscribe,
							silent: true
						});
						
						this.step ++;
					}
				}
				
			} catch (error) {
				console.log(error);
			}			
		},
		async doDelivery(formData, actions) {
			try {
				
				if (formData.dirty) {
					// update customer record
					await this.$store.dispatch('auth/updateCustomer', {
						firstName: formData.firstName,
						lastName: formData.lastName,
						businessName: formData.businessName||null,
						shipName: formData.firstName + ' ' + formData.lastName,
						shipPhone: formData.shipPhone,
						shipAddress1: formData.shipAddress1,
						shipAddress2: formData.shipAddress2||null,
						shipTown: formData.shipTown,
						shipCounty: formData.shipCounty||null,
						shipPostcode: formData.shipPostcode,
						shipCountry: formData.shipCountry,
					});					
				}
				
				// create payment intent
				const {data} = await PaymentService.paymentIntent({
					items: this.basketItems,
					total: this.vatTot, // for server side validation
					secret: this.paymentSecret,
				});
				
				this.paymentSecret = data.paymentSecret;
				
				this.step ++;
				
			} catch (error) {
				// update basket based on error response
				if (error.response.status === 422) {
					const reason = error.response.data.reason;
					
					if (reason === 'weight') {
						actions.setFieldError('shipCountry', 'Order weight exceeded for this country, please place two orders');
						
					} else if (reason === 'total') {
						actions.setFieldError('shipCountry', 'Order total discrepancy, please empty your basket and try again');
						
					} else if (reason === 'customer') {
						actions.setFieldError('shipCountry', error.response.data.message);
						
					} else if (reason === 'album') {
						actions.setFieldError('shipCountry', error.response.data.message);
						
					} else if (reason === 'stock') {
						actions.setFieldError('shipCountry', error.response.data.message);
					}
				}
			}
		},
		async doPayment() {
			try {
//				if (typeof this.curUser === 'undefined') {
//					return;
//				}
				
				// direct to stripe
				const {paymentIntent, error} = await stripe.confirmCardPayment(
					this.paymentSecret,
					{
						payment_method: {
							card: card,
						},
					},
				);
				
				if (error) {
					let displayError = document.getElementById('card-errors');
					displayError.textContent = error.message;
					
				} else if (paymentIntent.status === 'succeeded') {
					// call api
					const {data} = await PaymentService.paymentComplete({
						id: paymentIntent.id,
						user: this.curUser,
//						items: this.basketItems, // now unused
					});
					
					this.orderRef = data.orderRef;
					
					// clear basket
					this.paymentSecret = null;
					this.emptyBasket();
					this.reloadAlbums();
					
					this.step ++;
				}
				
			} catch (error) {
				// update basket based on error response
//				if (error.response.status === 422) {
//					const reason = error.response.data.reason;
//					if (reason === 'mintsoft') {
//					}
//				}
				console.log(error.response.data);
			}
		},
	},
	computed: {
		recoverAccount() {
			return this.$store.state.auth.recoverAccount;
		},
		isActive() {
			return this.$store.getters['basket/isActive'];
		},
		basketItems() {
			return this.$store.getters['basket/basketItems'];	
		},
		basketQty() {
			return this.$store.getters['basket/basketQty'];	
		},
		basketItemQty() {
			return this.$store.getters['basket/basketItemQty'];	
		},
		basketWeight() {
			return this.$store.getters['basket/basketWeight'];
		},
		itemsTot() {
			return this.$store.getters['basket/itemsTot'];
		},
		donationTot() {
			return this.$store.getters['basket/donationTot'];
		},
		basketTot() {
			return this.$store.getters['basket/basketTot'];
		},
		hasNonStockItem() {
			return this.$store.getters['basket/hasNonStockItem'];	
		},
		paymentTot() {
			return this.basketTot + this.deliveryCharge;
		},
		vatTot() {
			return this.paymentTot + this.vatCharge;
		},		
		curUser() {			
			// check if logged in
			return this.$store.state.auth.user;
		},
		shipCountry: {
			get() {
				return this.curUser?.customer?.shipping?.address?.country||'GB';
			},
			set(countryCode) {
				this.shipZone = this.pandp.countries.find(c => c.code === countryCode).zone;
			}
		},
		business: {
			get() {
				return (this.curUser.customer?.businessName) ? true : false;
			},
			set() {}
		},
		pickingCharge() {
			return this.pandp.picking.first + ((this.basketItemQty - 1) * this.pandp.picking.extra);
		},
		packingCharge() {
			return this.pandp.packing;
		},
		courierCharge() {
			const weights = this.pandp.courier.find(d => d.zone === this.shipZone).grams;
			return weights.find(g => this.basketWeight <= g.limit).charge;
		},
		courierService() {
			const weights = this.pandp.courier.find(d => d.zone === this.shipZone).grams;
			return weights.find(g => this.basketWeight <= g.limit).service;
		},
		deliveryCharge() {
			return this.pickingCharge + this.packingCharge + this.courierCharge;
		},
		vatCharge() { // ignores donations and row orders (vat exempt)
			let vat = (this.shipZone !== 'row') ? ((this.itemsTot + this.deliveryCharge) * this.pandp.vatPct) : 0;
			return Math.round((vat + Number.EPSILON) * 100) / 100;
		},
	},	
};
</script>

<style lang="scss">
.fadebusy-enter-active {
	transition: opacity .3s ease-in-out;
}
.fadebusy-enter-from,
.fadebusy-leave-to {
	opacity: 0;
}
.fadestep-enter-active {
	transition: opacity .6s ease-in-out;
	>:not(.ctrl) {
		transition: opacity .3s .3s ease-in-out;
	}
}
.fadestep-enter-from,
.fadestep-leave-to {
	>:not(.ctrl) {
		opacity: 0;
	}
}
.fadestep-leave-active {
	visibility: hidden;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity .3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
.fadedelay-enter-active {
	height: 0;
	transition: opacity .3s .3s ease-in-out;
}
.fadedelay-leave-active {
	transition: opacity .3s 0s ease-in-out;
}
.fadedelay-enter-from,
.fadedelay-leave-to {
	opacity: 0;
}
#card-element {
	max-width: calc(100% - 20px);
}
#basket {
	z-index: 130;
	position: fixed;
	filter: none;
	top: 20px;
	right: 20px;
	display: flex;
    flex-direction: column;
	transform: translateX(calc(100% + 70px));
	transition: transform .3s ease-in-out;
	height: calc( 100vh - 40px );
	width: calc( 50% - 150px );
	min-width: 380px;
	max-width: 500px;
	min-height: 140px;
	box-shadow: 0 2px 50px 0px rgb(#000, .2);
	background: #FFF;
	p:not(:last-child) {
		margin-bottom: 10px;
	}
	.legal {
		font-size: 1.2rem;
	}
	>div {
		overflow-x: hidden;
		overflow-y: scroll;
		height: 100%;
		margin-bottom: 100px;
	}
	.close {
		z-index: 1;
		position: absolute;
		top: 10px;
		right: 10px;
	}
	li {
		list-style: none;		
	}
	.steps {
		display: flex;
		height: 60px;
		min-height: 60px;
		align-items: center;
		padding: 0 5px;
		background: $green;
		overflow: hidden;
		li {
			position: relative;
			padding: 0 10px;
			font-size: 1.2rem;
			font-weight: 600;
			text-transform: uppercase;
			transition: color .3s ease-in-out;
			&:before {
				content: '';
				width: 20px;
				height: 20px;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translate(-50%, 25px) rotate(45deg);
				background: #FFF;
				transition: transform .3s ease-in-out;
			}
			&:not(.active) {
				color: rgba(#FFF, .5);
			}
			&.done {
				color: #FFF;				
			}
			&.active {
				color: #FFF;
				&:before {
					transform: translate(-50%, 15px) rotate(45deg);
				}
			}
			@media only screen and (max-width: 339px) {
				font-size: 1.1rem;
			}
		}
	}
	.busy {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		bottom: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $white;
		font-size: 1.2rem;
		text-transform: uppercase;
		background: rgba($green, 1);
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0 2px 50px rgb(#000, .2);
		}
		span {
			transform: translateY(40px);
		}
	}	
	.items {
		padding: 10px;
		p.empty {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			color: $lightgrey;
		}
		ul:first-of-type {			
			li {
				position: relative;
				background: rgba(0,0,0,.025);
				padding: 15px 15px;
				border-radius: 5px;
				margin-bottom: 10px;
				.price {
					margin-right: 0;
				}
			}
			p {
				font-size: 1.8rem;
				text-align: center;
				color: $lightgrey;
			}
		}
		.price {
			margin-left: 10px;
			flex-grow: 1;
			text-align: right;
		}
	}
	.delivery {
		.legal {
			width: 100%;
			max-width: calc(100% - 20px);
		}
	}
	.complete {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 20px 20px 0 20px;
		h6 {
			color: $green;
		}
		p {
			font-size: 1.4rem;
			strong {
				color: $green;
			}
		}
		button {
			margin-top: 20px;
		}
	}
	form {
		width: calc( 100% + 20px ); // hide scrollbar
		padding: 20px;
	}
	fieldset {
		max-width: calc( 100% - 20px );
	}
	p.small {
		font-size: 1.4rem;
	}
	.green {
		color: $green;	
	}
	h6,
	label,
	legend {
		font-size: 1.6rem;
		font-weight: normal;
		margin-bottom: 10px;
	}
	hgroup {
		padding-right: 40px;
		margin-bottom: 10px;
		h5 {
			font-size: 1.6rem;
			font-weight: normal;
		}
		h6 {
			font-size: 1.4rem;
			font-weight: 500;
		}
	}
	span.sku {
		display: block;
		font-size: 1rem;
		line-height: 1;
	}
	.qty {
		display: flex;
		>label,
		>span {
			font-size: 1.2rem;
			line-height: 26px;
			margin-right: 5px;
			font-weight: 600;			
		}
		>label {
			width: auto;
			padding-left: 15px;
			margin-right: 0;
			&:after {
				content: '£';
				display: inline-block;
				transform: translateX(-50px);
				color: $green;
				font-size: 1.4rem;
				font-weight: normal;
			}
		}
		input { // donation
			width: 60px;
			height: 26px;
			line-height: 26px;
			margin-left: 5px;
			padding: 0 10px 0 16px;
			color: $green;
			border-radius: 12px;
			border: 2px solid $green;
			background: none;
		}
		>em {
			display: block;
			font-style: normal;
			font-size: 1.4rem;
			height: 26px;
			text-align: center;
			line-height: 26px;
			margin-left: 5px;
		}
		.add,
		.sub {
			position: relative;
			border: 2px solid $green;
			svg {
				position: absolute;
				top: 50%;
				width: 24px;
				height: 24px;
				transform: translateY(-50%);
			}
			&:not(:disabled):hover {
				background: $green;
				.fill {
					fill: $white;
				}				
			}
			&:disabled {
				.fill {
					fill: #CCC;
				}
			}
		}
		.add {
			border-radius: 0 15px 15px 0;
			border-left: 0;
			svg {
				right: 0;
			}
		}
		.sub {
			border-radius: 15px 0 0 15px;
			border-right: 0;
			svg {
				left: 0;
			}
		}
		.price {
			strong {
				position: absolute;
				top: 16px;
				right: 0;
				margin: 0;			
			}
		}
	}
	.add,
	.sub,
	.bin {
		position: relative;
		width:26px;
		height: 26px;
		text-indent: 26px;
		white-space: nowrap;
		overflow: hidden;
		background: none;
		transition: .3s ease-in-out;
	}	
	.bin {
		position: absolute;
		top: 10px;
		right: 10px;
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 34px;
			height: 34px;
			transform: translate(-50%, -50%);
			&:hover {
				.fill {
					fill: $black;
				}
			}
		}		
	}
	.ctrl {
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;		
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		&:before {
			content: '';
			background: $white;
			position: absolute;
			top: -20px;
			right: -20px;
			bottom: -20px;
			left: -20px;
			box-shadow: 0 2px 50px rgba(#000,.2);
		}
		.price {
			margin-left: 0 !important;
			margin-top: 0 !important;
			text-align: left;
		}
		.but-price {
			button {
				width: 100px;
			}			
		}
	}
	@media only screen and (max-width: 380px) {
		label {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
			&.hlf {
				width: 100%;
			}
		}
		.qty {
			flex-wrap: wrap;
		}
	}
	@media only screen and (max-width: 768px) {
		top: 0;
		right: 0;
		height: 100%;
		.items {
			max-height: calc( 100vh - 160px );
		}
		.ctrl {
			justify-content: center;
			.empty {
				display: none;
			}			
		}
	}
	#card-element {
		margin-bottom: 20px;
	}
	table {
		width: calc(100% - 20px);
		border: 0;
		margin-bottom: 20px;
		th, td {
			font-size: 1.4rem;
			vertical-align: top;
		}
		th {
			font-weight: normal;
			text-align: left;
			padding-right: 20px;
		}
		td {
			text-align: right;
		}
		.price em {
			font-size: 1.4rem;
		}
	}
}
body.show-basket {
	overflow: hidden;
	header,
	#app >:not(#basket) {
		// blur non-basket children
		pointer-events: none;
		filter: blur(4px);			
	}
	#basket {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);		
		@media only screen and (max-width: 540px) {
			min-width: 100%;
		}
	}
	#logo {
		filter: blur(4px);
		pointer-events: none;
	}
}
</style>