<template lang="pug">
main#account
	//-h1 My Account
	nav#subnav
		ul
			li 
				router-link(:to="{name:'Profile'}") 
					span Profile
			li 
				router-link(:to="{name:'Orders'}")
					span Orders
			li(v-if="user.roleId===4")
				router-link(:to="{name:'Projects'}")
					span Projects
			//-li(v-if="user.roleId===4")
				router-link(:to="{name:'Users'}")
					span Users
			//-li(v-if="user.id===1")
				router-link(:to="{name:'Development'}")
					span Dev
			li
				button.solid.text.green(type="button" @click="signout()")
					span Sign Out
	router-view(v-slot="{Component}")
		//-transition(name="fademodal")
		component(:is="Component")
</template>

<script>
export default {
	name: 'Account',
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
	},
	methods: {
		async signout() {
			try {
				await this.$store.dispatch('auth/signout');
				this.$router.push({ name: 'SignIn' });
				
			} catch (error) {
				console.log(error.response.data.errors);
			}
		},		
	},
};
</script>

<style lang="scss">
#account {
	max-width: 1260px;
	margin: auto;
	padding: 80px 40px;		
}
#subnav {
	pointer-events: none;
	z-index: 10;	
	position: sticky;
	top: 80px;
	text-align: center;
	margin-bottom: 80px;
	ul {
		pointer-events: all;
		display: inline-flex;
		justify-content: center;
//		max-width: 600px;
		margin: auto;
		padding-left: 10px;
		height: 60px;
		border-radius: 30px;
		background: $white;
		box-shadow: 0 2px 10px rgba(#000, .2);
	}
	li {
		position: relative;
		list-style-type: none;
		padding: 10px;
	}
	a {
		display: inline-block;
		margin: 10px;
		color: $green;
		span {
			z-index: 3;
			position: relative;
			background: $white;
		}
		&:before {
			z-index: 1;
			content: '';
			width: 20px;
			height: 20px;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -24px) rotate(45deg);
			background: $white;
			transition: all 0.3s ease-in-out;			
			box-shadow: 0 2px 10px rgba(#000, .2);
		}
		&:after {
			z-index: 2;
			content: '';
			position: absolute;
			bottom: 0;
			left: 20px;
			right: 20px;
			height: 20px;
			background: $white;
			border-radius: 0 0 5px 5px;
		}
		&.router-link-active {
			color: $black;
			&:before {
				transform: translate(-50%, -14px) rotate(45deg);
			}			
		}
		&:hover {
			color: $black;
		}
	}
	.solid span:before {
		box-shadow: none;
	}
}
@media only screen and (max-width: 539px) {
	#account {
		padding: 80px 20px;		
	}
}
@media only screen and (max-width: 413px) {
	#subnav {
		margin-left: -20px;		
		margin-right: -20px;		
		ul {
			min-width: 320px;
			padding: 0 10px 0 20px;
			justify-content: space-between;				
		}
		li {
			padding: 10px 0;			
		}
		a {
			margin: 10px 0;	
			&:before {
				box-shadow: none;
			}
		}
	}
}
</style>