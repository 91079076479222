// check for local storage of basket (saved via store.subscribe, see: store/index.js)
const store = JSON.parse(localStorage.getItem('basket'));
const initialState = store ? store : { items: [], active: false, added: false, };

let addedTimeout = null;

export const basket = {
	namespaced: true,
	state: initialState,
	mutations: {
		addItem(state, {item, type}) {
			if (type === 'album') {
				state.items.push({
					type: type,
					qty: 1,
					item: {
						id: item.id,
						trrSku: item.trrSku,
						catNum: item.catNum,
						title: item.title,
						artist: item.artistName,
						cover: item.artworkCover,
						price: item.price,
						grams: item.grams,
						state: item.state,
					},
					donation: 0,
				});		
			} else {
				// TODO: merchandise (phase 2)
			}
		},
		incrementItem(state, index) {
			state.items[index].qty ++;
		},
		removeItem(state, index) {
			state.items.splice(index, 1);
		},		
		decrementItem(state, index) {
			state.items[index].qty --;
		},
		removeAllItems(state) {
			state.items = [];
		},
		toggleBasket(state, bool) {
			state.active = bool;
		},
		itemDonation(state, {index, donation}) {
			state.items[index].donation = donation;
		},
		flagAdded(state, bool) {
			state.added = bool;
		},
	},
	actions: {
		showBasket({ commit }) {
			document.body.classList.add('show-basket');
			commit('toggleBasket', true);
		},
		hideBasket({ commit }) {
			document.body.classList.remove('show-basket');
			commit('toggleBasket', false);
		},
		toggleBasket({ commit, getters }) {
			const bool = !getters.isActive;
			commit('toggleBasket', bool);
		},
		addToBasket({ commit, getters }, {item, type}) {
			const index = getters.inBasket(item, type);
			if (index >= 0) {
				commit('incrementItem', index);
			} else {
				commit('addItem', {item, type});
			}
			
			if (type === 'album') {
				commit('albums/decrementStock', { album: item, decrement: 1 }, { root: true });
			} else {
				// TODO: merchandise (phase 2)
			}
			
			if (addedTimeout) {
				clearTimeout(addedTimeout);
			}
			
			commit('flagAdded', true);
			
			addedTimeout = setTimeout(function() {
				commit('flagAdded', false);
			}, 2000);
		},
		removeOneFromBasket({ commit, getters }, {item, type}) {
			const index = getters.inBasket(item, type);
			
			if (index >= 0) {
				const qty = getters.itemQty(item, type);
				
				if (qty > 1) {
					commit('decrementItem', index);
				} else {
					commit('removeItem', index);
				}
				
				if (type === 'album') {
					commit('albums/incrementStock', { album: item, increment: 1 }, { root: true });
				} else {
					// TODO: merchandise (phase 2)
				}
			}
		},
		removeFromBasket({ commit, getters }, {item, type}) {
			const index = getters.inBasket(item, type);
			
			if (index >= 0) {
				const qty = getters.itemQty(item, type);
				
				commit('removeItem', index);
				
				if (type === 'album') {
					commit('albums/incrementStock', { album: item, increment: qty }, { root: true });
				} else {
					// TODO: merchandise (phase 2)
				}
			}
		},
		emptyBasket({ commit, getters }) {			
			if (getters.basketQty) {
				// TODO: update stock for every basket item
				for (const i of getters.basketItems) {
					if (i.type === 'album') {
						commit('albums/incrementStock', { album: i.item, increment: i.qty }, { root: true });
					} else {
						// TODO: merchandise (phase 2)
					}
				}
				
				commit('removeAllItems');
			}
		},
		updateDonation({ commit, getters }, {item, type, donation}) {
			const index = getters.inBasket(item, type);
			if (index >= 0) {
				commit('itemDonation', {index, donation});
			}
		},		
		initiateStock({ commit, getters, rootGetters }) {
			// decrement album stock if in basket
			const albums = rootGetters['albums/allAlbums'];
			const items = getters.basketItems;
			
			for (const [index, i] of items.entries()) {
				if (i.type === 'album') {
					for (const album of albums) {
						if (album.id === i.item.id) {
							let qty = i.qty;
//							const remaining = ((album.stock - album.sold) - qty);
							const remaining = (album.stock - qty);
							
							if (remaining < 0) {
								// less stock than qty in basket
								qty += remaining;
								
								if (qty <= 0) {
									// remove item from basket
									commit('removeItem', index);
								} else {
									// reduce qty in basket
									for(let i=1; i<= Math.abs(remaining); i++) {
										commit('decrementItem', index);
									}
									// decrement stock
									commit('albums/decrementStock', { album, decrement: qty }, { root: true });
								}
								
							} else {
								// decrement stock
								commit('albums/decrementStock', { album, decrement: qty }, { root: true });
							}
							
							break;
						}
					}
				} else {
					// TODO: merchandise (phase 2)
				}
			}
		},
	},
	getters: {
		addedNotice: (state) => {
			return state.added;
		},
		isActive: (state) => {
			// returns basket show/hide state (bool)
			return state.active;
		},
		basketItems: (state) => {
			// returns basket items array
			return state.items;
		},
		inBasket: (state) => (item, type) => {
			// returns item index if in basket or -1
			return state.items.findIndex(i => i.item.id === item.id && i.type === type);
		},
		hasNonStockItem: (state) => {
			// basket contains non-stock items
			const index =  state.items.findIndex(i => i.item.state !== 'stock');			
			return (index >= 0) ? true : false;
		},
		itemQty: (state, getters) => (item, type) => {
			// returns basket quantity for supplied item
			const index = getters.inBasket(item, type);
			return (index >= 0) ? state.items[index].qty : 0; 
		},
		basketQty: (state) => {
			// returns number of unique basket items
			return state.items.length;
		},
		basketItemQty: (state) => {
			// returns total number of basket items
			const qtys = state.items.map(i => i.qty);
			return qtys.reduce((a, b) => a + b, 0);
		},
		basketWeight: (state) => {
			// returns weight of basket items in grams
			return state.items.reduce((total, i) => total + (i.qty * i.item.grams), 0);
		},
		itemsTot: (state) => {
			// returns item total
			return state.items.reduce((total, i) => total + (i.qty * i.item.price), 0);
		},
		donationTot: (state) => {
			// returns total donations
			return state.items.reduce((total, i) => total + parseInt(i.donation), 0);
		},
		basketTot: (state, getters) => {
			// returns total basket price
			return getters.itemsTot + getters.donationTot;
		},
	}
};
