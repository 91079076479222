<template lang="pug">
include ./pug/svg
header
	button#menu.icon.pulse(type="button" @click="toggleMenu")
		+svg(svg-filename="iconMenu") Toggle Menu
	nav#nav
		ul
			li
				router-link(:to="{name:'Shop'}" @click="closeMenu") Shop Vinyl
			li
				router-link(:to="{name:'About'}" @click="closeMenu") About RR
			li
				router-link(:to="{name:'Profile'}" @click="closeMenu") Account
	ul#top
		li
			BasketButton(id="bag")
		li#user
			router-link.but.icon.pulse(:to="{name:'Profile'}") 
				+svg(svg-filename="iconAccount")
				span Account
			ul.submenu(v-if="!currentUser && currentUser")
				li(v-if="!currentUser")
					router-link(:to="{name:'SignIn'}") Sign In
				li(v-if="!currentUser")
					router-link(:to="{name:'SignUp'}") Sign Up
				li(v-if="currentUser") 
					a(@click.prevent="signOut") Sign Out
		//-li#share
			button.text(type="button" aria-controls="network") 
				span Share
			ul#network(role="menu")
				li
					a(href="https://www.instagram.com/therecordrepublic/" target="_blank") Instagram
						+svg(svg-filename="iconInstagram")
				li
					a(href="https://twitter.com/RecordRepublic" target="_blank") Twitter
						+svg(svg-filename="iconTwitter")
				li
					a.pulse(href="https://www.facebook.com/RecordRepublic" target="_blank") Facebook
						+svg(svg-filename="iconFacebook")
		li#follow
			button.text(type="button" aria-controls="social") 
				span Follow
			ul#social(role="menu")
				li
					a(href="https://www.instagram.com/therecordrepublic/" target="_blank") Instagram
						+svg(svg-filename="iconInstagram")
				li
					a(href="https://twitter.com/RecordRepublic" target="_blank") Twitter
						+svg(svg-filename="iconTwitter")
				li
					a.pulse(href="https://www.facebook.com/RecordRepublic" target="_blank") Facebook
						+svg(svg-filename="iconFacebook")
router-view
Basket
footer
	CookieNotice
	p.copy &#169; The Record Republic 2022. Company No. 13249269 
</template>

<script>
/*
router-view(v-slot="{Component}" @addedToBasket="itemAdded")
	transition(name="fadeview" mode="out-in" enter-active-class="fadeview" leave-active-class="fadeview")
		component(:is="Component")
*/
import Basket from './components/Basket.vue'
import BasketButton from './components/BasketButton.vue'
import CookieNotice from './components/CookieNotice.vue'

export default {
	name: 'App',
	components: {
		Basket,
		BasketButton,
		CookieNotice,
	},
	data() {
		return {
			showMenu: false,
		};
	},
	computed: {
		currentUser() {
			// check if logged in
			return this.$store.state.auth.user;
		},
	},
	methods: {
		closeMenu() {
			this.showMenu = false;
			document.body.classList.remove('show-menu');
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
			
			if (this.showMenu) {
				document.body.classList.add('show-menu');
			} else {
				document.body.classList.remove('show-menu');
			}
		},
		signOut() {
			this.$store.dispatch('auth/signOut');
			this.$router.push('/signin');
		},
	},
	beforeCreate() {
		// notify console of mode vue is running in (development||production)
		console.log('VUE_ENV:', process.env.VUE_APP_VUE_ENV);
	},
};
</script>

<style lang="scss">
.fademodal-enter-active,
.fademodal-leave-active {
	transition: opacity .3s ease-in-out;
}
.fademodal-enter-from,
.fademodal-leave-to {
	opacity: 0;
}
.album li:nth-child(4),
#left { // phase two
	display: none !important;
}
#app {
	padding-top: 80px;
//	>.modal {
	.modal {
		z-index: 110;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($lightgrey, .85);
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding: 80px 40px;	
		>div {
			display: flex;
			max-width: 1400px;
			margin: auto;
		}
	}
	@media only screen and (max-width: 539px) {
		.modal {
			padding: 80px 20px;	
		}
	}
	.modal-nav {
		z-index: 1;
		li {
			list-style-type: none;
			@media only screen and (min-width: 540px) {
				&:first-child {
					margin-right: auto;
				}
			}
			@media only screen and (max-width: 539px) {
				&:nth-child(2) {
					margin-right: auto;
				}
			}
			&:nth-child(n+2) {
				margin-left: 10px;
			}
		}
	}
	.prev {
		svg {
			transform: rotate(180deg);
		}
	}
}
	
	
#app >:not(.modal):not(#basket) {
	transition: all .6s ease-in-out, filter .3s ease-in-out;	
}
body[class^="modal-"] {
	overflow: hidden;
//	header,
//	#app >:not(.modal):not(#basket) {
//		pointer-events: none;
//		filter: blur(4px);			
//	}
}
body[class^="modal-"] header,
body.modal-search #app >:not(.modal):not(#basket),
body.modal-preview #app >:not(.modal):not(#basket),
body.modal-order #account #subnav,
body.modal-order #account #orderlist {
	pointer-events: none;
	filter: blur(4px);
}

main {
	transition: all 2s ease-in-out;
	min-height: calc( 100vh - 80px );
	&.fadeview {
		opacity: 0;
	}
}
section {
	ol:not(.raw),
	ul:not(.raw) { // raw removes styling
		>li {
			position: relative;
			list-style-type: none;
			line-height: 1.4;
			padding-left: 16px;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
	ul:not(.raw) {
		li:before {
			content: '';
			position: absolute;
			top: 7px;
			left: 0;
			width: 6px;
			min-width: 6px; // prevent squish! 
			height: 6px;
			background: $black;
			border-radius: 50%;
		}
	}
	ol:not(.raw) {
		counter-reset: li;
		>li {
			counter-increment: li;
			padding-left: 20px;
			&:before {
				content: counters(li,'.') ': ';
				display: inline-flex;
				width: 20px;
				min-width: 20px; // prevent squish! 
				margin-left: -20px;
				color: $black;
			}
		}
	}
}
header,
.modal-nav {
	position: fixed;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);	
	max-width: 1600px;
	width: 100%;
	padding: 0 20px;
	display: flex;
	align-items:center;
	justify-content: space-between;
}
header {
	z-index: 100;
	> a {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		img {
			display: block;
			height: 40px;
			width: auto;			
		}
	}
}
#nav {
	ul {
		display: flex;
		height: 40px;
		align-items: center;
	}
	li {
		display: flex;
		align-items: center;
		list-style-type: none;
//		&:not(:last-child):after {
		&:first-child:after {
			content: '/';
			display: inline-flex;
			margin-top: -3px;
			padding: 0 5px;
			font-size: 3rem;
			font-weight: 300;
			color: #FFF;
		}
	}
	a {
		font-size: 1.4rem;
		font-weight: 500;
		color: #FFF;
		&:hover,
		&.router-link-active {
			color: $black;			
		}
	}
}
#top,
#left {
	li {
		list-style-type: none;
		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
#top {
	display: flex;
	li {
		position: relative;
		margin-right: 10px;
	}
	.submenu {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		background: #FFF;
		border-radius: 4px;
		padding: 5px 10px;
		li {
			width: 100%;
			padding: 5px 0;			
		}
		a {
			display: block;
			text-transform: uppercase;
			font-size: 1.2rem;
			font-weight: 500;
			white-space: nowrap;
			color: #56B9A0;
		}
	}
}
#left {
	z-index: 110;
	position: fixed;
	top: 50%;
    left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
    max-width: 1600px;
	padding: 0 20px;
	pointer-events: none;
	li {
		margin-bottom: 10px;
	}
	button {
		width: 50px;
		height: 50px;
		pointer-events: auto;
	}
}
em.qty {
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	line-height: 46px;
	font-size: 1rem;
	font-weight: 600;
	font-style: normal;
	color: #56B9A0;
	margin-left: .25px;
	transition: all .3s ease-in-out;
}
#share,
#follow {
	position: relative;
	width: 80px;
	transform: translateZ(0);
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 40px;
		background: #FFF;
		border-radius: 20px;
		transition: all .3s .3s ease-in-out;
	}
	button {
		z-index: 1;
		pointer-events: none;
		position: relative;
		width: 100%;
		background: none;
		transition: all .3s .6s ease-in-out;
	}
	ul {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		height: 0px;
		width: 100%;
		padding: 20px;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		border-radius: 40px;
		box-shadow: 0 2px 10px rgba(#000, .2);
		transform: translateZ(0);
		transition: height .3s 0s ease-in-out, opacity .3s 0s ease-in-out;
	}
	li:not(:last-child) {
		margin-bottom: 10px;
	}
	a {
		position: relative;
		display: block;
		width: 40px;
		height: 40px;
		text-indent: 40px;
		white-space: nowrap;
		overflow: hidden;
		border-radius: 50%;
		transition: all .3s ease-in-out;
		background: #FFF;
		&:hover {
			background: $green;
			.fill {
				fill: #FFF;
			}
		}
	}
	svg {
		z-index: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all .3s ease-in-out;
	}		
	.fill {
		fill: $green;
	}
	&:hover {
		&:before {
			height: 180px;
			background: #FFF;
			border-radius: 40px;
			transition-delay: 0s;
		}
		button {
			opacity: 0;
			visibility: hidden;				
			transition-delay: 0s;
		}
		ul {
			height: 180px;
			opacity: 1;
			visibility: visible;
		}
		li {
			transform: scale(1) translateZ(0);
		}
	}
}
footer {
//	position: relative;
	.copy {
		z-index: 101;
		position: fixed;
		bottom: 10px;
		left: 0;
		right: 0;
		pointer-events: none;
		text-align: center;
		color: $white;
		font-size: 1.2rem;
	}
}

	
	
	
	

fieldset {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
//	max-width: calc( 100% - 20px );
	div {
		width: 100%;
	}
}
label,
input {
	font-size: 1.4rem;
}
label {
	font-size: 1.6rem;
	font-weight: normal;
	margin-bottom: 10px;
	pointer-events: none;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	&.below {
		margin-top: -20px;
		>button.a,
		>input.a + span { // checkbox
			margin-right: 10px;
			font-size: 1.2rem;
		}
	}
	>:not(label) {
//	*:not(label) {
		pointer-events: auto !important; // re-enable
	}	
	&.hlf {
		width: calc( 50% - 10px );
	}
	[role="alert"] {
		position: absolute;
		left: 0;
		bottom: -5px;
		width: 100%;
		padding-left: 10px;
	}
	span {
		order: 1;
		position: relative;
		transition: .3s ease-in-out;
		font-size: 1.4rem;
	}
	.signout {
		font-style: normal;
		font-size: 1.2rem;
		margin: -5px 0 0 auto;
		a {
			color: $green;
			text-decoration: underline;
			&:hover {
				text-decoration-color: transparent;
			}
		}
	}
}
#card-errors,
label >[role="alert"] {
	font-style: normal;
	font-size: 1.1rem;
	line-height: 1;			
	color: $error;
}
select,
input {
	display: block;
	appearance: none;
	border: 1px solid $grey;
	border-radius: 4px;
	color: $black;
}
select,
[type="text"],
[type="number"],
[type="password"] {
	width: 100%;
	height: 40px;
	padding: 10px 10px 0 10px;
	transform-origin: left top;
	margin-bottom: 10px; // space for error message
	&:disabled {
		cursor: not-allowed;
		color: $grey;
		background: $white;
	}
	~ span {
		position: absolute !important;
		top: 20px;
		left: 10px;
		transform: translateY(-50%);
		color: $grey;
	}
	&.err {
		
	}
}
label.price {
	&:before {
		content: '£';
		pointer-events: none;
		position: absolute;
		top: 10px;
		left: 10px;
		height: 29px;
		font-size: 1.4rem;
		display: flex;
		align-items: center;		
		opacity: 0;
		//transition: opacity .3s ease-in-out;
	}
	input {
		padding-left: 20px;
	}
	&.symbol {
		&:before {
			opacity: 1;
		}
	}
}
[type="text"],
[type="number"],
[type="password"] {
	&:focus,
	&:not(:placeholder-shown) {
		~ span {
			transform: translate(-14%, -100%) scale(0.7);
		}
	}
}
select {
	cursor: pointer;
	&:not(.empty) {
		~ span {
			transform: translate(-14%, -100%) scale(0.7);
		}		
	}
	~ svg {
		pointer-events: none !important;
		position: absolute;
		top: 0;
		right: 0;
		transform: rotate(90deg);
	}
	&:disabled {
		~svg {
			.fill {
				fill: $lightgrey;
			}
		}
	}
	&:not(:disabled):hover {
		~svg {
			.fill {
				fill: $black;
			}
		}
	}
}
button.a,
[type="checkbox"].a + span { // look like text link
	margin-left: auto;
	padding: 0;
	font-size: 1.2rem;
	line-height: 2rem;
	color: $green;
	transition: all .3s ease-in-out;
	text-decoration: underline;
	text-decoration-color: transparent;
	background: none;
	&:hover {
		color: $grey;
		text-decoration-color: inherit;
	}		
}
button.a:disabled,
[type="checkbox"].a:disabled + span {
	cursor: not-allowed;
	color: $lightgrey !important;
	text-decoration-color: transparent !important;		
}
[type="checkbox"] {
	cursor: pointer;
	width: 40px;
	height: 20px;
	background: $grey;
	border: 0;
	transition: .3s ease-in-out;
	width: 36px;
	border-radius: 10px;
	&.a {
		visibility: hidden;
		+ span {
			flex-grow: unset !important;
			&:before {
				display: none;
			}
		}
	}
	~ span {
		cursor: pointer;
		line-height: 20px;
		&:nth-last-of-type(2) {
			order: -1;
			padding: 0 10px 0 0;
		}
		&.unchecked {
			color: $lightgrey;
			text-decoration: line-through;
		}
	}
	+ span {
		&:before { // input toggle marker
			pointer-events: none;
			content: '';
			position: absolute;
			top: 2px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: #FFF;
			transition: all .3s ease-in-out;	
		}
	}
	&:checked {
		background: $green;
		+ span:before {
			transform: translateX(100%);
		}				
	}
	&:not(.aorb):not(.bora) {
		position: absolute;
		top: 0;
		left: 0;			
		+ span {
			flex-grow: 1;
			padding-left: 46px;
			&:before {
				left: 2px;
			}
		}
	}
	&.aorb,
	&.bora {
		background: $green;
		+ span:before {
			left: calc( 100% + 2px );
		}
		~ span:last-of-type {
			padding-left: 10px;
		}
	}
	&.bora {
		&:checked {
			+ span:before {
				transform: translateX(0);
			}			
		}
		&:not(:checked) {
			+ span:before {
				transform: translateX(100%);
			}			
		}
	}
}
@media only screen and (min-width: 1170px) {
	footer {
		.copy {
			padding-right: 60px;
			text-align: right;			
		}
	}
}
@media only screen and (max-width: 567px) {
	#top li {
		margin-right: 0;
	}
	#top >:not(:first-child) {
		display: none;
	}
	#follow {
		display: none;
	}
}
@media only screen and (min-width: 568px) {
	#nav {
		li:last-child { // account
			display: none;
		}
	}
	}
@media only screen and (max-width: 736px) {
	body.show-menu {
		overflow: hidden;
		header ~:not(#basket):not(footer) {
			pointer-events: none;
			filter: blur(4px);
		}
		#nav {
			opacity: 1;
			visibility: visible;			
		}
	}
	#nav {
		position: absolute;
		top: -20px;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		padding: 80px 20px 20px 20px;		
		background: rgba($green, .9);
		opacity: 0;
		visibility: hidden;
		transition: .3s ease-in-out;
		ul {
			flex-direction: column;
			width: 100%;
			height: 100%;
			justify-content: center;
		}
		li {
			width: 100%;
			justify-content: center;
			&:after {
				display: none !important;
			}
		}
		a {
			margin-bottom: 20px;
			line-height: 40px;
			font-size: 3rem;
			&.router-link-active {
				color: #FFF;
			}
		}		
	}
	.album button.icon span	 {
		overflow: hidden;
	}
	#left {
		top: auto;
		bottom: 20px;
		transform: translate(-50%, 0);
		li {
			height: 40px;
			width: 40px;
		}
		button {
			transform: translateY(0) scale(.8);
			transform-origin: left top;
		}
	}
	#menu {
		z-index: 1;
		display: block;
		height: 40px;
		border-radius: 50%;
		background: #FFF;
	}
}
@media only screen and (min-width: 737px) {
	#menu {
		display: none;
	}
	#nav {
		display: block;
	}
}
</style>