<template lang="pug">
include ../pug/svg
button.bag.icon.pulse(type="button" :class="{once:addedNotice}" @click="showBasket")
	+svg(svg-filename="iconBasket")
	span Show Basket
	em.qty(aria-label="Items in basket:") {{basketQty}}
</template>

<script>
export default {
	name: 'BasketButton',
	computed: {
		basketQty() {
			return this.$store.getters['basket/basketQty'] || '';	
		},
		addedNotice() {
			return this.$store.getters['basket/addedNotice'];				
		}
	},	
	methods: {
		async showBasket() {
			// reload albums from database
			await this.$store.dispatch('albums/dbAllAlbums');
			// check stock quantities against basket items
			await this.$store.dispatch('basket/initiateStock');
			// reveal basket
			this.$store.dispatch('basket/showBasket');
		},
/*		itemAdded() {
			// add/remove css animation
			this.bagHint = true;
			
			setTimeout(function() {
				this.bagHint = false;				
			}.bind(this), 2000);
		},*/
	},
};
</script>

<style lang="scss">
.pulse.once {
	background: $green;
	.fill {
		fill: #FFF;		
	}
	.stroke {
		stroke: #FFF;
	}		
	em {
		color: #FFF;
	}
}	
</style>