<template lang="pug">
h2 Page Not Found!
</template>

<script>
export default {
	name: 'Error404'
}
</script>

<style lang="scss">
</style>