import httpApi from '../httpApi';

class AccountService {
	getCustomers() {
		return httpApi.get('/account/customers');
	}
	
	getProjects() {
		return httpApi.get('/account/projects');
	}
	
	getOrders() {
		return httpApi.get('/account/orders');
	}
	
	getOrderPayment(id) {
		return httpApi.get('/account/orderPayment/' + id);
	}
	
	getOrderByIntent(id) {
console.log('getOrderByIntent:',id);
		return httpApi.get('/account/orderByIntent/' + id);
	}
	
	updateProfile(data) {
		return httpApi.put('/account/update', data);		
	}
}

export default new AccountService();