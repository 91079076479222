import { createStore } from 'vuex';
import { auth } from './authModule';
import { albums } from './albumsModule';
import { basket } from './basketModule';

const store = createStore({
	modules: {
		auth,
		albums,
		basket
	}
});

// subscribe to store updates
store.subscribe((mutation, state) => {
	// subscribe has to be global so check for correct store update
	if (mutation.type.startsWith('basket/')) { // e.g. module/commit
		// store basket in local storage
		const basket = JSON.stringify(state.basket);
		
		localStorage.setItem('basket', basket);
	}
});

export default store;