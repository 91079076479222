// payment data service
import httpApi from '../httpApi';

class PaymentService {
	// api calls
	getCountries() {
		return httpApi.get('/payment/countries');
	}
	
	getDelivery() {
		return httpApi.get('/payment/delivery');
	}
	
//	getCustomer(email) {
//		return httpApi.get('/payment/customer', email);
//	}
	
	upsertCustomer(data) {		
		return httpApi.post('/payment/customer', {
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			businessName: data.businessName,
			shipPhone: data.shipPhone,
			shipName: data.shipName,
			shipAddress1: data.shipAddress1,
			shipAddress2: data.shipAddress2,
			shipTown: data.shipTown,
			shipCounty: data.shipCounty,
			shipPostcode: data.shipPostcode,
			shipCountry: data.shipCountry,
		});
	}
	
	paymentIntent(data) {
		return httpApi.post('/payment/intent', {
			items: data.items,
			total: data.total,
			secret: data.secret,
		});
	}
	
	paymentComplete(data) {
		return httpApi.post('/payment/complete', {
			id: data.id,
			user: data.user,
//			items: data.items, // unused
		});
	}
}

export default new PaymentService();