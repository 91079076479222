import { createApp } from 'vue';
import App from './App.vue';
import router from './router/';
import store from './store';
//import VueMeta from 'vue-meta';

const app = createApp(App);

app.use(router);
app.use(store);
//app.use(VueMeta);
app.mount('#app');

//require('./freqtimeupdate.js')