// auth data service
import httpApi from '../httpApi';

class AuthService {
	status() {
		return httpApi.get('/auth/status');
	}
	
	identify(data) {
		return httpApi.post('/auth/identify', {
			email: data.email
		});
	}
	
	signup(data) {
		return httpApi.post('/auth/signup', {
			email: data.email,
			password: data.password,
			subscribe: data.subscribe,
			silent: data.silent||false
		});
	}
	
//	verify(data) {
//		return httpApi.post('/auth/verify', {
//			verification: data.verification
//		});
//	}
	
	signin(data) {
		return httpApi.post('/auth/signin', {
			email: data.email,
			password: data.password
		});
	}
	
	recover(email) {
		return httpApi.post('/auth/recover', {
			email: email
		});
	}
	
	reset(data) {
		return httpApi.post('/auth/reset', {
			email: data.email,
			otpCode: data.otpPassword,
			password: data.newPassword
		});
	}
	
	signout() {
		return httpApi.get('/auth/signout');
	}
}

export default new AuthService();