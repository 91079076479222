<template lang="pug">
include ../pug/svg
section#signin
	Form(ref="authForm" @submit="doSubmit" :validation-schema="signSchema" v-slot="{ errors, isSubmitting, values, meta, handleReset }")
		fieldset
			label.toggle
				Field(name="hasAccount" type="checkbox" class="aorb" :value="true" :uncheckedValue="false" v-model="hasAccount" @click="handleReset")
				span(:class="{unchecked:values.hasAccount}") Register
				span(:class="{unchecked:!values.hasAccount}") Sign In 
		fieldset(v-if="hasAccount")
			legend Sign in
			label
				Field(name="email" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.email}")
				span Email
				transition(name="fade")
					ErrorMessage(name="email" as="em")
			transition(name="fadedelay")
				div(v-if="!recoverAccount")
					label
						Field(name="password" type="password" placeholder="Required" autocomplete="off" :class="{err:errors.password}")
						span Password
						transition(name="fade")
							ErrorMessage(name="password" as="em")
					label.below
						Field.a(name="forgotPassword" type="checkbox" :value="true" :uncheckedValue="false" @click="initiateRecovery(values.email)" :disabled="!values.email||errors.email")
						span Forgotten your password?
			transition(name="fadedelay")
				div(v-if="recoverAccount")
						Field(name="forgotPassword" type="hidden" v-model="recoverAccount")
						p.small.green We've emailed your password reset code&hellip;
						label
							Field(name="otpPassword" type="password" placeholder="Required" autocomplete="off" :class="{err:errors.otpPassword}")
							span Password Reset Code
							transition(name="fade")
								ErrorMessage(name="otpPassword" as="em")
						label
							Field(name="newPassword" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.newPassword}")
							span New Password
							transition(name="fade")
								ErrorMessage(name="newPassword" as="em")
						label.below
							button.a(name="forgotCancel" type="button" @click="cancelRecovery()") Cancel reset					
		fieldset(v-else)
			legend Register
			label
				Field(name="email" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.email}")
				span Email
				transition(name="fade")
					ErrorMessage(name="email" as="em")
			label
				Field(name="createPassword" type="text" placeholder="Required" autocomplete="off" :class="{err:errors.createPassword}")
				span Create Password
				transition(name="fade")
					ErrorMessage(name="createPassword" as="em")
			label
				Field(name="subscribe" type="checkbox" :value="true" :uncheckedValue="false")
				span Keep me up to date on new releases and offers
				
		fieldset.ctrl
			button.solid.text(:disabled="!meta.valid||isSubmitting" type="submit") {{submitLabel}}
				//-span.loading(v-show="isSubmitting") Loading
		//-pre errors: {{errors}}
		//-pre values: {{values}}
		//-pre meta: {{meta}}
</template>

<script>
import AuthService from '../services/AuthService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup); // extend yup

export default {
	name: 'SignIn',
	components: {
		Form,
		Field,
		ErrorMessage
	},
	data() {
		const signSchema = Yup.object().shape({
			email: Yup.string().label('Email').email().required(),
			password: Yup.string().label('Password').when(['hasAccount','forgotPassword'], {
				is: (hasAccount, forgotPassword) => hasAccount && !forgotPassword,
				then: Yup.string().required()
			}),
			createPassword: Yup.string().label('Password').when('hasAccount', {
				is: false,
				then: Yup.string().password().required()
			}),
			otpPassword: Yup.string().label('Password Reset Code').when('forgotPassword', {
				is: true,
				then: Yup.string().required()
			}),
			newPassword: Yup.string().label('Password').when('forgotPassword', {
				is: true,
				then: Yup.string().password().required()
			}),
		});		
		
		return {
			signSchema,
			hasAccount: true,
		};
	},
	computed: {
		submitLabel() {
			return (this.hasAccount === true) ? 'Sign In' : 'Sign Up';
		},
		recoverAccount() {
			return this.$store.state.auth.recoverAccount;
		},		
	},
	methods: {
		async initiateRecovery(email) {
			try {
				await this.$store.dispatch('auth/initiateRecovery', {
					email: email,
				});
				
			} catch (error) {
				// supplied email failed recovery
				this.$refs.authForm.setFieldError('email', 'Unknown email address');
			}
		},
		cancelRecovery() {
			this.$store.dispatch('auth/cancelRecovery');
		},		
		async doSubmit(formData, actions) {
			try {
				if (formData.hasAccount === true) {
					if (formData.forgotPassword === true) {
						// account recovery
						await this.$store.dispatch('auth/verifyRecovery', {
							email: formData.email,
							otpPassword: formData.otpPassword,
							newPassword: formData.newPassword
						});
						
						if (!this.$store.state.auth.loggedIn) {
							// supplied credentials failed auth
							actions.setFieldError('otpPassword', 'Invalid password reset code, please try again');
						} else {
							this.$router.push({ name: 'Profile' });							
						}
						
					} else {
						// account signin
						await this.$store.dispatch('auth/signin', {
							email: formData.email,
							password: formData.password
						});
						
						if (!this.$store.state.auth.loggedIn) {
							// supplied credentials failed auth
							actions.setFieldError('password', 'Invalid password, please try again');
							
						} else {
							this.$router.push({ name: 'Profile' });
						}
					}
					
				} else {
					// check for existing non-guest account
					const {data} = await AuthService.identify(formData);
					
					if (data.exists) {
						actions.setFieldError('email', 'This email is already taken');
						
					} else {
						// create account
						const subscribe = (formData.subscribe === true) ? 1 : 0;
						
						await this.$store.dispatch('auth/signup', {
							email: formData.email,
							password: formData.createPassword,
							subscribe: subscribe,
							silent: false
						});
						
						this.$router.push({ name: 'Profile' });
					}					
				}
			} catch (error) {
				console.log(error.response.data.errors);
			}
		},
	},
};	
</script>

<style lang="scss">
#signin {
	padding: 80px 40px;	
	form {
		display: flex;
		flex-direction: column;
		margin: auto;
		max-width: 460px;
		min-height: 390px;
		padding: 40px 40px 20px 40px;
		border-radius: 4px;
		background: #FFF;
		font-size: 1.4rem;		
		box-shadow: 0 2px 10px rgba(#000, .2);
	}
	h2 {
		margin-bottom: 40px;
		text-align: center;
	}
	label {
		span {
//			font-size: 1.4rem;
		}
		&.hlf {
			>[type="checkbox"] {
				top: 10px;
				+ span {
					margin-top: 10px;					
				}
			}
		}
	}
	label.toggle {
		width: auto;
		margin: auto;
		margin-bottom: 20px;
	}
	fieldset {
		margin-top: auto;
		margin-bottom: 20px;
		&:nth-last-child(2) {
			flex-grow: 1;
		}
		&.ctrl {
			justify-content: center;
			button {
				margin: 0 10px;
			}
		}
	}
	legend {
		font-size: 1.4rem;
		margin-bottom: 20px;
		font-family: 'SansBold';
		text-align: center;
		text-transform: uppercase;
		letter-spacing: .2em;
	}
	p.small {
		margin-bottom: 10px;
		color: $green;
		text-align: center;		
	}
}
</style>